
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

import Dialog from "primevue/dialog";
import Button from "primevue/button";

import CustomerService from "@/services/CustomerService";

const custService = new CustomerService();

export default defineComponent({
  name: "OrderSalesActionDialog",
  components: {
    Button,
    Dialog,
  },
  props: {
    show: Boolean,
    order: {} as any,
    header: {
      type: String,
      default: "Sales Actions",
    },
  },
  data() {
    return {
      showOrderSalesActionDialog: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      posShowEditOrder: "mrkControl/posShowEditOrder",
      getOrder: "pos/getCurrentOrder",
      getStoredCartItems: "pos/getStoredCartItems",
      getClient: "session/getClient",
    }),
  },
  methods: {
    ...mapActions({
      addNotification: "notification/add",
      addPartToOrder: "pos/addPartToOrder",
      setLastItemChanged: "pos/setLastItemChanged",
      validateSalesOrder: "pos/validateSalesOrder",
      setSalesOrder: "pos/setSalesOrder",
      setStoredCartItems: "pos/setStoredCartItems",
      setCust: "pos/setCustomer",
    }),
    async addSalesOrderToSummary() {
      if (
        this.getOrder.filter((item: any) => item.so_id === this.order.so_id)
          .length == 0
      ) {
        this.setStoredCartItems({
          items: [this.order],
          type: "summaryOrder",
        });
        this.addPartToOrder(this.order);
        this.getCustomer(this.order);
      } else {
        this.setLastItemChanged("").then(() => {
          this.setLastItemChanged(this.order.so_id);
        });
      }
    },
    async getCustomer(data: any) {
      try {
        await custService
          .getCustomer(
            data.sold_to,
            this.getClient,
            "contact_email contact_name rep_name",
          )
          .then((response: any) => {
            this.setCust({ cust_items: [response] });
          })
          .catch((error: any) => {
            this.addNotification({
              message: "Error Getting Customer",
              type: "error",
            });
          });
      } catch (error) {
        this.addNotification({
          message: "Error Getting Customer",
          type: "error",
        });
      }
    },
    async addOrderToParts() {
      this.showOrderSalesActionDialog = false;

      await this.setSalesOrder({
        order: JSON.parse(JSON.stringify(this.order)),
        oldOrder: JSON.parse(JSON.stringify(this.order)),
        date_created: new Date(this.order.date),
        racks: [],
      });
      this.validateSalesOrder({
        metaData: { validate_only: "Y", validate_reason: "CUSTOMER_SELECTED" },
      });
      this.$router.push("/pos/parts");
    },
  },
  watch: {
    show(newShow) {
      this.showOrderSalesActionDialog = newShow;
    },
  },
});
